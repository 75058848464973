html {
	scroll-behavior: smooth;
}

body {
  background-color: #2561b3;
  background-image: linear-gradient(110deg, #2561b3ef 0 20%, #2561b31f 90% 100%), url("background.JPG");
  background-size: cover;
}

.App {
  text-align: center;
  
}

.App-logo {
  height: 35px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.appbar {
  z-index: 100;
}

.page_container {
  z-index: 50;
  margin-left: auto;
  margin-right: auto;
  /* min-width: 900px;  */
   max-width: 1200px;
  /* max-width: 900px; */
  padding-top: 60px;
}

.card {
  margin-top: 30px;
}

.cardText br {
  display:block; 
  margin-top:10px; 
  line-height:22px; 
  content: " ";
}

.nav-item > .active {
  border-bottom: 1px solid #333;
  
}

.barLink {
  color:#2561b3;
  cursor: pointer;
  font-weight: bold;
  margin-right: 15px;
  text-decoration: underline;

}

.barLink :hover {
  font-weight: bolder;
}
